
import { ThemeProvider } from "styled-components";
import { useState, useEffect } from "react";
import { darkTheme, lightTheme } from './utils/Themes.js'
import Navbar from "./components/Navbar";
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Feed from "./Feed.js";
import Footer from "./Footer.js"
import styled from "styled-components";
import db from "./firebase";
import Sidebar from "./Sidebar.js";
import { FaHome, FaFire, FaHistory, FaVideo, FaHeart } from 'react-icons/fa';
import { YouTube, WhatsApp } from "@mui/icons-material";
import {TextSmall, TextSmallLink, LinkText} from "./components/text/StyledTexts"

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%,30% 98%, 0 100%);
`
const Title1 = styled.h2`
  color: ${({ theme }) => theme.text_primary};
  text-align: center;
`;
const Text = styled.p`
  color: ${({ theme }) => theme.bl};
  text-align: center;
  padding: 10px;
`;
const StyledLink = styled.a`
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`;
// firebase hosting:clone reiko:live reikom:live
function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [openModal, setOpenModal] = useState({ state: false, project: null });
   const [isOpen, setIsOpen] = useState(true);
   useEffect(() => {
    // Function to handle window resize event
    const handleResize = () => {
      // Check if the screen width is less than a certain threshold (e.g., 768px for mobile devices)
      if (window.innerWidth < 768) {
        setIsOpen(false); // Set isOpen to false if on mobile
      } else {
        setIsOpen(true); // Set isOpen to true if not on mobile
      }
    };

    // Call handleResize on initial load
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup: remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to run only on initial load


  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    // <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
    <ThemeProvider theme={lightTheme}>
     <Sidebar isOpen={isOpen}>
       
      </Sidebar>
        <div>
        <Navbar toggleSidebar={toggleSidebar} />
          {/* Sidebar component */}
      <Sidebar isOpen={isOpen}>
      <div className="sidebar-content">
        <a href="https://chat.whatsapp.com/KzrsEXWPxavCH5F15vTVf5" target="_blank" className="icon-link"><div className="sidebar-item">
          <WhatsApp  className="sidebar-icon" />
          <span className="sidebar-text">Whatsapp Group</span>
        </div></a>
        <a href="https://www.youtube.com/@reikom" className="icon-link"><div className="sidebar-item">
          <YouTube className="sidebar-icon" />
          <span className="sidebar-text">Reikom</span>
        </div></a>
        <a href="https://www.youtube.com/@ReikomAcademy" className="icon-link"><div className="sidebar-item">
          <YouTube className="sidebar-icon" />
          <span className="sidebar-text">Reikom Academy</span>
        </div></a>
        {/* <br/>
        <TextSmall>Build your online presence and start selling a service or a digital product</TextSmall> */}
        <br/>
        <LinkText className="sidebar-item"><a href="https://youtu.be/MHIMJ6dCcxY?si=NYQKT58s6i1f2NO5" target="_blank">Business ideas you can start now</a></LinkText>
        <LinkText className="sidebar-item"><a href="https://youtu.be/pbAxP693sxk?si=g-3qqBSEVCaoGYL8" target="_blank">Skills to learn to start making money online</a></LinkText>
        <LinkText className="sidebar-item"><a href="https://youtu.be/kKaRTMrszpg?si=H2ZQMRQ_1uBwH9W_" target="_blank">Why you should become an artist</a></LinkText>
        <br/>

        <TextSmallLink><a className="link-behavior">About</a> •<a className="link-behavior"> Terms and Conditions</a> • <a className="link-behavior">Privacy Policy</a></TextSmallLink>
 
        
      </div>
      </Sidebar>
        <Body>
    
         <Feed/>
         <div className="main-container">
         </div>
         <Footer/>
        </Body>
        </div>
    </ThemeProvider>
  );
}

export default App;

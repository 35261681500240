// Sidebar.js
import React from 'react';
import './Sidebar.css'; // Import CSS file for styling

const Sidebar = ({ isOpen, children }) => {
    console.log('Is open:', isOpen);
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="sidebar-content">{children}</div>
    </div>
  );
};

export default Sidebar;

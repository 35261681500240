import styled from "styled-components";

export const Text = styled.p`
  color: ${({ theme }) => theme.bl};
  text-align: start;
`;
export const TextSmall = styled.p`
  color: ${({ theme }) => theme.bl};
  text-align: start;
  font-size: 14px;
  padding-bottom: 5px;
`;
export const TextSmallLink = styled.p`
  color: ${({ theme }) => theme.bl};
  text-align: start;
  font-size: 12px;
`;
export const LinkText = styled.p`
  color: ${({ theme }) => theme.bl};
  text-align: start;
  font-size: 13px;

`;
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Details from './pages/DetailsPage'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import DownloadPage from './pages/DownloadPage';
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/details",
    element: <Details/>,
  },
  {
    path: "/download-page",
    element: <DownloadPage/>,
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


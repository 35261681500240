import React from 'react'
import { Nav, NavLink, NavbarContainer, Span, NavLogo, NavItems, GitHubButton, ButtonContainer, MobileIcon, MobileMenu, MobileNavLogo, MobileLink } from './NavbarStyledComponent'
import { DiCssdeck } from 'react-icons/di';
import { FaBars } from 'react-icons/fa';
import { Bio } from '../../data/constants';
import { Close, CloseRounded } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import { useEffect, useState } from "react";
import {auth,provider} from "../../firebase_config/firebase";
import {signInWithPopup} from "firebase/auth";

const Navbar = ({ toggleSidebar }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const theme = useTheme()
  const [value,setValue] = useState('')
    const handleClick =()=>{
        signInWithPopup(auth,provider).then((data)=>{
            setValue(data.user.email)
            localStorage.setItem("email",data.user.email)
        })
    }

    useEffect(()=>{
        setValue(localStorage.getItem('email'))
    })
    const logout =()=>{
      localStorage.clear()
      window.location.reload()
  }
  return (
    <Nav>
      <NavbarContainer>
      {/* <button className="toggle-button" onClick={toggleSidebar}>
        Toggle Sidebar
      </button> */}
      <MobileIcon>
      <FaBars onClick={toggleSidebar}/>
      </MobileIcon>
        <NavLogo to='/'>
          <a style={{ display: "flex", color: "white", cursor: 'pointer' }}>
            <Span>Reikom</Span>
          </a>
        </NavLogo>
        {/* <MobileIcon>
          <FaBars onClick={() => {
            setIsOpen(!isOpen)
          }} />
        </MobileIcon> */}
        <NavItems>
          {/* <NavLink href="#about">About</NavLink>
          <NavLink href='#skills'>Skills</NavLink>
          <NavLink href='#experience'>Experience</NavLink>
          <NavLink href='#projects'>Projects</NavLink>
          <NavLink href='#education'>Education</NavLink> */}
        </NavItems>
        <ButtonContainer>
        {value? <GitHubButton onClick={logout} >Logout</GitHubButton> : <GitHubButton onClick={handleClick} >Sign in</GitHubButton>}
        </ButtonContainer>
        
      </NavbarContainer>
    </Nav>
  )
}

export default Navbar


import React, { useRef, useEffect } from "react";
import { useNavigate  } from 'react-router-dom';

export default function Paypal() {
  const paypal = useRef();
  const navigate = useNavigate();
  const productData = JSON.parse(sessionStorage.getItem('productData'));

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Buy this book",
                amount: {
                  currency_code: "USD",
                  value: productData.price,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          // const pdfUrl = 'https://firebasestorage.googleapis.com/v0/b/reikom.appspot.com/o/books%2Fpdfs%2FNo%20degree%20-%20Emmanuel%20Mambwe.pdf?alt=media&token=a9906322-4ad0-46c8-b1e8-9f6ad47bc22e';

          // // Create a link element
          // const link = document.createElement('a');
          // link.href = pdfUrl;
          // link.target = '_blank'; // Open the PDF in a new tab
          // link.download = 'No degree - Emmanuel Mambwe.pdf'; // Set the filename for download
      
          // // Simulate a click event on the link to trigger the download
          // link.click();

          // Navigate to a new page
          navigate('/download-page');
        },
        onError: (err) => {
          console.log(err);
        },
      })
      .render(paypal.current);
  }, [navigate]);

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}

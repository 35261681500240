import React, { useState, useEffect } from "react";
import db from "./firebase";
import FlipMove from "react-flip-move";
import Post from "./Post";
function Feed() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    db.collection("products").onSnapshot((snapshot) =>
      setPosts(snapshot.docs.map((doc) => doc.data()))
    );
  }, []);
  return (
    <div >
      <div>
      </div>

     

      <FlipMove>
        {posts.map((post) => {
          //  console.log(posts);
            return (
                <Post
                    key={post.name}
                    productName={post.name}
                    price={post.price}
                    image={post.image}
                    post={post}
                     />
            );
        })}
      </FlipMove>
    </div>
  );
}

export default Feed;

import React, { forwardRef } from "react";
import { Avatar } from "@material-ui/core";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import RepeatIcon from "@material-ui/icons/Repeat";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import PublishIcon from "@material-ui/icons/Publish";
import styled from "styled-components";
const Text = styled.p`
  color: ${({ theme }) => theme.bl};
  text-align: start;
`;
const Post = forwardRef(
  ({productName,image, price,post}, ref) => {
    // Before navigating to the '/details' page
sessionStorage.setItem('productData', JSON.stringify(post));

    return (
      <div className="post" ref={ref}>
        
        <a href={`/details?productName=${productName}price=${productName}`} className="title-link">
          <div className="flex-container">
            <div className="product-card">
              <img className="product-image" src={image} />
              
              <div className="card-details">
              <Text>{productName}</Text>
              <Text><small>$</small>{price}</Text>
             
              </div>
            </div>
          
           
            
          </div></a>
        </div>
   
    );
  }
);

export default Post;

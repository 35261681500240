import React from 'react'
import Navbar from '../components/Navbar'
import PayPal from "../components/PayPal";
import styled from "styled-components";
import { useEffect, useState } from "react";
import {auth,provider} from "../firebase_config/firebase";
import {signInWithPopup} from "firebase/auth";
const Text = styled.p`
  color: ${({ theme }) => theme.bl};
  text-align: start;
`;
function Details() {
  const [value,setValue] = useState('')
    const handleClick =()=>{
        signInWithPopup(auth,provider).then((data)=>{
            setValue(data.user.email)
            localStorage.setItem("email",data.user.email)
        })
    }

    useEffect(()=>{
        setValue(localStorage.getItem('email'))
    })
    const logout =()=>{
      localStorage.clear()
      window.location.reload()
  }
  const [checkout, setCheckOut] = useState(false);
  // Retrieve the data from session storage
const productData = JSON.parse(sessionStorage.getItem('productData'));

  return (
    
    <div>
      <Navbar/>
      <div className='details-container'>
      <div className="product-card">
              <img className="product-image" src={productData.image}/>
              
              <div className="card-details">
             
              </div>
            </div>
            <div >
            <Text className='card-details'>{productData.name}</Text>
            <Text  className='card-details'><small >$</small>{productData.price}</Text>
            <p>{productData.productDetails}</p>
            </div>
           <div className='buy-card'>
           {checkout ? (
        <PayPal />
      ) : (
        <button class="buy-button"
          onClick={() => {
            setCheckOut(true);
          }}
        >
          Buy
        </button>
      )}
           <p>Payment: secure payment by Paypal</p>
           

           </div>

        

      </div>
      <div>
           <p>{productData.productInfo}</p>
           </div>
    </div>
  )
}

export default Details
import React from 'react'
import Navbar from '../components/Navbar';

function DownloadPage() {
//     const el = document.getElementById('overlayBtn');
// if (el) {
//   el.addEventListener('click', swapper, false);
// }
  const el =  document.getElementById('downloadButton');
  
  if(el){
    el.addEventListener('click', async () => {
        var link = document.createElement('a');
        link.href = 'https://firebasestorage.googleapis.com/v0/b/reikom.appspot.com/o/books%2Fpdfs%2FNo%20degree%20-%20Emmanuel%20Mambwe.pdf?alt=media&token=a9906322-4ad0-46c8-b1e8-9f6ad47bc22e';
        link.download = 'file.pdf';

        link.dispatchEvent(new MouseEvent('click'));
    });}
    
  return (
    <div>
       <Navbar/>
       <div className='download-div'>
        <p>Your purchase was successful.</p>
       <button id="downloadButton">Download PDF</button>
       </div>
        

    </div>
  )
}

export default DownloadPage
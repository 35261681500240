import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import {getAuth,GoogleAuthProvider} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCEM0mVpcsi_RtRy_A4npgDSSg-gdzkn1s",
  authDomain: "reikom.firebaseapp.com",
  projectId: "reikom",
  storageBucket: "reikom.appspot.com",
  messagingSenderId: "869473392724",
  appId: "1:869473392724:web:0f7c5f5a23d545a3d1b5c1",
  measurementId: "G-P755JD424D"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

const auth  = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();
export {auth,provider};

export default db;
